<template>
    <div class="Service full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <div class="content-area pa-5 flex-grow-1 bg-white">
                <v-dialog ref="pathdialog" v-model="pathmodal" :return-value.sync="selectedPath" persistent width="500px">
                    <div>
                        <div class="dialogcontainer pa-8">
                            <p style="font-weight: 500" v-if="importModal">{{ $t('system.service.selectPathImport') }}</p>
                            <p style="font-weight: 500" v-else-if="importLicenseModal">{{ $t('system.service.selectPathImportLicense') }}</p>
                            <p style="font-weight: 500" v-else>{{ $t('system.service.selectPathExport') }}</p>
                            <v-list>
                                <v-list-item-group
                                    v-model="selectedPath"
                                    color="disiIrisblue"
                                    class="list mt-4 mb-8"
                                    id="pathdialog-list"
                                    mandatory
                                >
                                    <v-list-item
                                        v-for="(item) in this.pathlist"
                                        :key="item.id"
                                        :value="item.id"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <div class="button-container d-flex justify-space-between">
                                <v-btn elevation="0" class="content-btn small" @click="exportLog = false; importModal = false; importLicenseModal = false; pathmodal = false;">{{ $t('footerButton.cancel') }}</v-btn>
                                <v-btn v-if="importModal" elevation="0" class="content-btn small" :disabled="selectedPath == ''" @click="$refs.pathdialog.save(selectedPath); doImport()">{{ $t('footerButton.import') }}</v-btn>
                                <v-btn v-else-if="importLicenseModal" elevation="0" class="content-btn small"  :disabled="selectedPath == ''" @click="$refs.pathdialog.save(selectedPath); doImportLicense()">{{ $t('footerButton.import') }}</v-btn>
                                <v-btn v-else-if="exportLog" elevation="0" class="content-btn small"  :disabled="selectedPath == ''" @click="$refs.pathdialog.save(selectedPath); doLogExport()">{{ $t('footerButton.save') }}</v-btn>
                                <v-btn v-else elevation="0" class="content-btn small"  :disabled="selectedPath == ''" @click="$refs.pathdialog.save(selectedPath); doExport()">{{ $t('footerButton.save') }}</v-btn>
                            </div>
                        </div>
                    </div>
                </v-dialog>

                <v-row class=" mt-0">
                    <v-col cols="12" >
                        <h2 class="border-bottom mt-0 pb-4">Import / export data</h2>
                    </v-col>
                    <v-col cols="4" class="d-flex">
                        <div v-ripple class="content-btn text-center btnWithSuccess" @click="pathmodal = true" :class="{'disabled': !checkUserRights('systemServiceEdit')}">
                            <p v-show="!showExportProgress && !showExportSuccess">{{ $t('system.service.exportData') }}</p>
                            <div v-show="showExportProgress" class="progress">
                                <v-progress-circular :size="25" :width="3" color="white" indeterminate class=""></v-progress-circular>
                            </div>
                            <div v-show="showExportSuccess" class="successCheck"></div>
                        </div>
                    </v-col>
                    <v-col cols="4" class="d-flex">
                        <div v-ripple class="content-btn text-center btnWithSuccess" @click="importModal = true; pathmodal = true" :class="{'disabled': !checkUserRights('systemServiceEdit')}">
                            <p v-show="!showImportProgress && !showImportSuccess">{{ $t('system.service.importData') }}</p>
                            <div v-show="showImportProgress" class="progress">
                                <v-progress-circular :size="25" :width="3" color="white" indeterminate class=""></v-progress-circular>
                            </div>
                            <div v-show="showImportSuccess" class="successCheck"></div>
                        </div>
                    </v-col>
                </v-row>

                <v-row class=" mt-6">
                    <v-col cols="12" >
                        <h2 class="border-bottom mt-0 pb-4">{{ $t('system.service.licenses') }}</h2>
                    </v-col>
                    <v-col cols="12" class="d-flex">
                        <v-data-table
                            class="license-table"
                            disable-pagination
                            hide-default-footer
                            :headers="licenseTableHeaders"
                            :items="licenselist"
                            :expanded.sync="expanded"
                            item-key="id"
                            show-expand
                            @click:row="(item, slot) => toggleRow(item, slot)"
                        >
                            <template v-slot:item.status="{ item }">
                                <div class="bullet" :class="{'old': item.statusColor === 0, 'valid': item.statusColor === 1, 'expired': item.statusColor === 2}"></div>
                            </template>

                            <template v-slot:item.trialLicense="{ item }">
                                <input v-if="item.trialLicense === true " type="checkbox" checked disabled>
                                <input v-else type="checkbox" disabled>
                            </template>

                            <template v-slot:item.data-table-expand>
                                <div class="data-table-expandable-icon"></div>
                            </template>

                            <template v-slot:expanded-item="{ headers, item }" class="test">
                                <td :colspan="headers.length">
                                    <div>
                                        <p>{{ $t('system.service.license.partNumber') }}: {{ item.partNumber }}</p>
                                        <p>{{ $t('system.service.license.serialNumber') }}: {{ item.serialNumber }}</p>
                                        <p>{{ $t('system.service.license.comment') }}: {{ item.comment }}</p>
                                    </div>
                                </td>
                            </template>
                        </v-data-table>
                    </v-col>
                    <v-col cols="4" class="d-flex">
                        <div v-ripple class="content-btn text-center btnWithSuccess" @click="doRefreshLicenseOnline()" :class="{'disabled': !checkUserRights('systemServiceLicense')}">
                            <!--                        <div v-ripple class="content-btn text-center btnWithSuccess" @click="pathmodal = true" :class="{'disabled': !checkUserRights('systemServiceLicense')}">-->
                            <p v-show="!showLicenseRefreshProgress && !showLicenseRefreshSuccess">{{ $t('system.service.license.onlineRefresh') }}</p>
                            <div v-show="showLicenseRefreshProgress" class="progress">
                                <v-progress-circular :size="25" :width="3" color="white" indeterminate class=""></v-progress-circular>
                            </div>
                            <div v-show="showLicenseRefreshSuccess" class="successCheck"></div>
                        </div>
                    </v-col>
                    <v-col cols="4" class="d-flex">
                        <div v-ripple class="content-btn text-center btnWithSuccess" @click="importLicenseModal = true; pathmodal = true" :class="{'disabled': !checkUserRights('systemServiceLicense')}">
                            <p v-show="!showLicenseImportProgress && !showLicenseImportSuccess">{{ $t('system.service.license.importLicense') }}</p>
                            <div v-show="showLicenseImportProgress" class="progress">
                                <v-progress-circular :size="25" :width="3" color="white" indeterminate class=""></v-progress-circular>
                            </div>
                            <div v-show="showLicenseImportSuccess" class="successCheck"></div>
                        </div>
                    </v-col>
                </v-row>

                <v-row class="d-flex align-center mt-6">
                    <v-col cols="12" >
                        <h2 class="border-bottom mt-0 pb-4">{{ $t('system.service.debugging') }}</h2>
                    </v-col>
                    <v-col class="col-4 d-flex align-center">
                        <switch-on-off name="service-tracing" :disabled="!checkUserRights('systemServiceEdit')" :class="{'active': tracing}" class="mr-4"/>
                        <p>{{ $t('system.service.writeLog') }}</p>
                    </v-col>
                    <v-col cols="4" class="d-flex">
                        <v-btn elevation="0" class="content-btn btnWithSuccess" :disabled="!service.logAvailable" @click="exportLog = true; pathmodal = true"  :class="{'disabled': !checkUserRights('systemServiceEdit')}">
                            <p v-show="!showExportLogProgress && !showExportLogSuccess">{{ $t('system.service.saveDebuggingData') }}</p>
                            <div v-show="showExportLogProgress" class="progress">
                                <v-progress-circular :size="25" :width="3" color="white" indeterminate class=""></v-progress-circular>
                            </div>
                            <div v-show="showExportLogSuccess" class="successCheck"></div>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class=" mt-6">
                    <v-col cols="12" >
                        <h2 class="border-bottom mt-0 pb-4">{{ $t('system.service.factoryReset') }}</h2>
                    </v-col>
                    <v-col cols="4" class="d-flex">
                        <div v-ripple class="content-btn text-center" @click="factoryReset" :class="{'disabled': !checkUserRights('systemServiceEdit')}">
                            {{ $t('system.service.factoryReset') }}
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToSystem"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import SwitchOnOff from "@/components/SwitchOnOff.vue";
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'Service',
    components: {
        SwitchOnOff,
        FooterButton,
    },
    props: {
    },
    data () {
        return{
            pathmodal: false,
            selectedPath: '',
            showExportProgress: false,
            showExportSuccess: false,
            showImportProgress: false,
            showImportSuccess: false,
            showLicenseRefreshProgress: false,
            showLicenseRefreshSuccess: false,
            showLicenseImportProgress: false,
            showLicenseImportSuccess: false,
            showExportLogProgress: false,
            showExportLogSuccess: false,
            importModal: false,
            importLicenseModal: false,
            exportLog: false,
        }
    },
    computed: {
        ...mapState([
            'service',
            'pathlist',
            'licenselist'
        ]),
        tracing(){
            return this.$store.state.service.tracing;
        },
        licenseTableHeaders(){
            return [
                // { text: this.$t('system.service.license.status'), align: 'start', value: 'status' },
                // { text: this.$t('system.service.license.partNumber'), align: 'start', value: 'partNumber', sortable: false },
                // { text: this.$t('system.service.license.serialNumber'), align: 'start', value: 'serialNumber', sortable: false },
                // { text: this.$t('system.service.license.trialLicense'), align: 'start', value: 'trialLicense', sortable: false },
                // { text: this.$t('system.audit.desc'), align: 'start', value: 'comment', sortable: false },
                // { text: '', value: 'data-table-expand'}
                { text: this.$t('system.service.license.status'), align: 'start', value: 'status' },
                { text: this.$t('system.service.license.licenseType'), align: 'start', value: 'licenseType', sortable: false },
                { text: this.$t('system.service.license.validFrom'), align: 'start', value: 'validFrom', sortable: false },
                { text: this.$t('system.service.license.validDuration'), align: 'start', value: 'validDuration', sortable: false },
                { text: this.$t('system.service.license.trialLicense'), align: 'start', value: 'trialLicense', sortable: false },
                { text: '', value: 'data-table-expand'}
            ]
        }
    },
    methods:{
        toggleRow(item, slot){
            console.log({item, slot});
            slot.expand(!slot.isExpanded);
            console.log({item, slot});
        },
        doImportLicense(){
            this.showLicenseImportProgress = true;
            this.importLicenseModal = false;
            this.$store.dispatch('postAxiosNoSetter', ['/admin/license/register', {'pathId': this.selectedPath}])
                .then(response =>{
                    this.showLicenseImportProgress = false;
                    if (response.status === 200){
                        this.showLicenseImportSuccess = true;
                        this.loadLicenses();
                        setTimeout(()=>{
                            this.showLicenseImportSuccess = false;
                        }, 800)
                    }
                });

        },
        doRefreshLicenseOnline(){
            this.showLicenseRefreshProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/admin/license/refreshOnline'])
                .then(response =>{
                    this.showLicenseRefreshProgress = false;
                    if (response.status === 200){
                        this.showLicenseRefreshSuccess = true;
                        this.loadLicenses();
                        setTimeout(()=>{
                            this.showLicenseRefreshSuccess = false;
                        }, 800)
                    }
                });
        },
        loadLicenses(){
            this.$store.dispatch('getAxiosSetter', ['/admin/license/list', 'licenselist']);
        },
        goToSystem(){
            this.$router.push('system');
        },
        factoryReset(){
            this.$confirm({message: this.$t('system.service.alertFactoryReset'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    this.$store.dispatch('getAxiosNoSetter', '/admin/service/factoryReset');
                })
                .catch(() => {
                    // cancel
                });
        },
        doExport(){
            this.showExportProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/admin/device/exportData', {'pathId': this.selectedPath}])
                .then(response =>{
                    this.showExportProgress = false;
                    if (response.status === 200){
                        this.showExportSuccess = true;
                        setTimeout(()=>{
                            this.showExportSuccess = false;
                        }, 800)
                    }
                });
        },
        doLogExport(){
            this.showExportLogProgress = true;
            this.exportLog = false;
            this.$store.dispatch('postAxiosNoSetter', ['/admin/device/traceExport', {'pathId': this.selectedPath}])
                .then(response =>{
                    this.showExportLogProgress = false;
                    if (response.status === 200){
                        this.showExportLogSuccess = true;
                        setTimeout(()=>{
                            this.showExportLogSuccess = false;
                        }, 800)
                    }
                });
        },
        doImport(){
            this.showImportProgress = true;
            this.importModal = false;
            this.$store.dispatch('postAxiosNoSetter', ['/admin/device/importData', {'pathId': this.selectedPath}])
                .then(response =>{
                    this.showImportProgress = false;
                    if (response.status === 200){
                        this.showImportSuccess = true;
                        setTimeout(()=>{
                            this.showImportSuccess = false;
                        }, 800)
                    }
                });
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/share/pathlist', 'pathlist']);
        this.$store.dispatch('getAxiosSetter', ['/admin/device/trace', 'service'])
            .then(response =>{
                if (response.status === 200){
                    this.$watch('$store.state.service.tracing', function() {
                        if (this.service.tracing){
                            this.$store.dispatch('postAxiosNoSetter', ['/admin/device/trace', {'tracing': true}])
                                .then(responseTrace =>{
                                    if (responseTrace.status === 200){
                                        this.$store.dispatch('getAxiosSetter', ['/admin/device/trace', 'service']);
                                    }
                                });
                        } else {
                            this.$store.dispatch('postAxiosNoSetter', ['/admin/device/trace', {'tracing': false}])
                                .then(responseTrace=>{
                                    if (responseTrace.status === 200){
                                        this.$store.dispatch('getAxiosSetter', ['/admin/device/trace', 'service']);
                                    }
                                });
                        }
                    });
                }
            });
        this.loadLicenses();
    }
}
</script>
<style scoped lang="scss">
h2{
    font-size: 1rem;
    margin-top: 36px;
}
.border-bottom{
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    max-width: 100%;
}
.v-application .content-btn:active, .v-application .content-btn:hover{
    background-color: $disiIrisblue;
}
.v-application .v-btn{
    letter-spacing: 0;
    text-transform: unset;
    &.theme--light.v-btn--has-bg{
        background-color: $disiDarkblueMedium;
        p{
            font-weight: 500;
        }
    }
}

.v-application .v-btn.v-btn--disabled{
    color: white!important;
    background-color: $disiLightIrisblue!important;
}
.btnWithSuccess{
    position: relative;
}
.progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: $disiIrisblue!important;
    transition: all 0.2s linear;
}
.successCheck{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: white;
    background-color: $disiIrisblue!important;
    transition: all 0.2s linear;
    &::before{
        content: "";
        background-image: url("../assets/OK_weiss.svg");
        height: 22px;
        width: 22px;
        margin-bottom: 2px;
        background-size: contain;
        background-position: center;
    }
}
.bullet{
    display: flex;
    align-items: center;
    &::before{
        content: "";
        display: inline-block;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $disiBluegreyDark;
    }
    &.valid::before{
        background-color: $disiIrisblue;
    }
    &.expired::before{
        background-color: $disiRed;
    }
    &.old::before{
        background-color: $disiBluegreyDark;
    }
}
.license-table{
    width: 100%;
    tr{
        background-color: yellow !important;
    }

    tr:nth-child(even) {
      background-color: $disiRed !important;

      &:hover, &:active {
        background-color: $disiRed !important;
      }
    }
    tr:nth-child(odd) {
      background-color: $disiIrisblue !important;

      &:hover, &:active {
        background-color: $disiIrisblue !important;
      }
    }
}
</style>
